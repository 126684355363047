.bg {
  background-color: #00b7aa;
  background-image: url(./assets/Background.png);
  /* width: 100%; */
}
/* *{
  font-family: 'Mulish', sans-serif;
} */

.PrivacyAndPolicy {
  font-family: 'Poppins', sans-serif;


}

body {
  width: 100%;
  height: 100vh;
  background-color: #f6f5f8;
  /* font-family: 'Mulish', sans-serif; */
  font-family: 'Poppins', sans-serif;
  background-size: cover;
}
.example::-webkit-scrollbar {
  display: none;
}

#svg-container:hover path {
  stroke: #f6f5f8; /* Change the stroke color to a different color on hover */
}

#svg-container-1:hover path {
  stroke: #f6f5f8;
  fill:#f6f5f8;
}
