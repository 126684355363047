@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-common {
    background: linear-gradient(to top, #000000,transparent);
}

.hero-section {
    font-family: 'Nunito Sans', sans-serif;
}